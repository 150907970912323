<div class="edit-landlord__content mb100">
  <p class="edit-landlord__subtitle uppercase-m semibold">
    {{ 'landlords.create_landlord_header' | translate }}
  </p>

  <app-wizard
    [steps]="allSteps"
    [currentStepNumber]="currentStep$ | async"
    [currentStepValid]="(currentForm$ | async)?.valid"
    [isProcessing]="(landlordActionState$ | async)?.pending"
    [floatingTop]="false"
  >
    <app-authentication
      *appWizardStep="let change"
      [form]="authenticationForm"
      [stepChange]="change"
      [steps]="allSteps"
      [stepIndex]="getStepIndex('authentication')"
      (onNextStep)="nextStep($event)"
    >
    </app-authentication>

    <app-preferences
      *appWizardStep="let change"
      [form]="preferencesForm"
      [stepChange]="change"
      [steps]="allSteps"
      [stepIndex]="getStepIndex('preferences')"
      (onNextStep)="nextStep($event)"
      (onPreviousStep)="previousStep()"
    >
    </app-preferences>
    <app-payment
      *appWizardStep="let change"
      [form]="paymentForm"
      [stepChange]="change"
      [steps]="allSteps"
      [stepIndex]="getStepIndex('payment')"
      (onNextStep)="nextStep($event)"
      (onPreviousStep)="previousStep()"
    >
    </app-payment>
    <app-object-hierarchy
      *appWizardStep="let change"
      [customerId]="userDataPayload?.customer?.id"
      [stepChange]="change"
      [steps]="allSteps"
      [stepIndex]="getStepIndex('objectHierarchy')"
      (onNextStep)="nextStep($event)"
      (onPreviousStep)="previousStep()"
    ></app-object-hierarchy>
    <app-subscription
      *appWizardStep="let change"
      [form]="subscriptionForm"
      [newPaymentMethod]="paymentMethod.value"
      [userData]="userDataPayload"
      [hasActiveProduct]="hasActiveProduct"
      [stepChange]="change"
      [steps]="allSteps"
      [stepIndex]="getStepIndex('subscription')"
      [landlordActionState]="landlordActionState$ | async"
      [recalculationActionState$]="recalculationActionState$"
      (saveQuotaDiscounts)="onSaveQuotaDiscounts($event)"
      (saveObjectContingentQuotaDiscounts)="
        onSaveObjectContingentQuotaDiscounts($event)
      "
      (finishEditLandlord)="onComplete()"
      (onPreviousStep)="previousStep()"
      (update)="onUpdate($event)"
      (create)="onCreate($event)"
      (createLandlord)="onCreateAccount()"
    >
    </app-subscription>
  </app-wizard>
</div>
