import { Routes } from '@angular/router';

import { EditLandlordStepsGuard } from '../../guards';

import { AuthenticationComponent } from './authentication/authentication.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { ObjectHierarchyComponent } from './object-hierarchy/object-hierarchy.component';

export const editLandlordStepsRoutes: Routes = [
  {
    path: 'authentication',
    component: AuthenticationComponent,
    data: {
      title: 'landlords.registration_step_1'
    }
  },
  {
    path: 'preferences',
    component: PreferencesComponent,
    canActivate: [EditLandlordStepsGuard],
    data: {
      title: 'landlords.registration_step_2',
      requireCompletion: 'authentication'
    }
  },
  {
    path: 'payment',
    component: PaymentMethodComponent,
    canActivate: [EditLandlordStepsGuard],
    data: {
      title: 'landlords.registration_admin_step_3',
      requireCompletion: 'preferences'
    }
  },
  {
    path: 'objectHierarchy',
    component: ObjectHierarchyComponent,
    canActivate: [EditLandlordStepsGuard],
    data: {
      title: 'landlords.registration_admin_step_object_hierarchy',
      requireCompletion: 'payment'
    }
  },
  {
    path: 'subscription',
    component: SubscriptionComponent,
    canActivate: [EditLandlordStepsGuard],
    data: {
      title: 'landlords.registration_admin_step_4',
      requireCompletion: 'payment'
    }
  }
];

export const editLandlordChildRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'authentication'
  },
  ...editLandlordStepsRoutes
];
