export enum ObjectHierarchyFieldTypes {
  ADDRESS = 'ADDRESS',
  AREA_DESCRIPTION = 'AREA_DESCRIPTION',
  ENERGY_CERTIFICATE = 'ENERGY_CERTIFICATE'
}

interface ObjectHierarchyData {
  name: string;
  fieldTypes: ObjectHierarchyFieldTypes[];
}

export interface ObjectHierarchy {
  id?: number;
  data?: ObjectHierarchyData;
  operativeType?: boolean;
  hierarchyLevel?: number;
}
