<form [formGroup]="form">
  <div class="row mt30">
    <div class="col-12 mb20">
      <app-form-field>
        <div class="d-flex flex-row">
          <app-form-field-label>{{
            'general.type_of_business_l' | translate
          }}</app-form-field-label>
          <app-hint class="pl5">Some hint</app-hint>
        </div>
        <app-select
          [required]="true"
          [itemLabelKey]="'name'"
          [itemValueKey]="'value'"
          [items]="businessTypes"
          [placeholder]="'forms.select_type' | translate"
          formControlName="customerType"
        >
        </app-select>
      </app-form-field>
    </div>
    @if (customerTypeControl.value && !isPrivate) {
      <div class="col-12 mb20">
        <app-form-field>
          <div class="d-flex flex-row">
            <app-form-field-label>{{
              'general.company_name_l' | translate
            }}</app-form-field-label>
            <app-hint class="pl5">Some hint</app-hint>
          </div>
          <input
            appInput
            type="text"
            [required]="customerTypeControl.value && !isPrivate"
            appAutofocus
            [placeholder]="'forms.type_name' | translate"
            formControlName="companyName"
            class="form-control"
          />
        </app-form-field>
      </div>
    }
    <div class="col-12 mb20">
      <app-form-field>
        <div class="d-flex flex-row">
          <app-form-field-label>{{
            'general.number_of_flats_under_management_l' | translate
          }}</app-form-field-label>
          <app-hint class="pl5">Some hint</app-hint>
        </div>
        <app-select
          [required]="true"
          [itemLabelKey]="'name'"
          [itemValueKey]="'value'"
          [items]="pricingPlan"
          [placeholder]="'forms.set_number' | translate"
          formControlName="customerSize"
        >
        </app-select>
      </app-form-field>
    </div>
    <div class="col-12 mb20">
      <app-form-field>
        <div class="d-flex flex-row">
          <app-form-field-label>{{
            'general.domain_l' | translate
          }}</app-form-field-label>
          <app-hint class="pl5">Some hint</app-hint>
        </div>
        <div class="input-group mb10">
          <input
            appInput
            type="text"
            [placeholder]="'forms.type_domain_name' | translate"
            formControlName="domainName"
            class="form-control"
          />
          <!-- @TODO ADMIN_KF - required was removed-->
          <div class="input-group-append">
            <span class="input-group-text">.immomio.de</span>
          </div>
        </div>
      </app-form-field>
    </div>
  </div>
</form>
