import { Routes } from '@angular/router';
import { EditLandlordComponent } from './components/edit-landlord';
import { LandlordComponent } from './landlord.component';

import { editLandlordChildRoutes } from './components/edit-landlord/edit-landlord.children';

export const ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'create'
  },
  {
    path: '',
    component: LandlordComponent
  },
  {
    path: 'create',
    component: EditLandlordComponent,
    children: editLandlordChildRoutes
  },
  {
    path: ':landlordId/edit',
    component: EditLandlordComponent,
    children: editLandlordChildRoutes
  }
];
