<form [formGroup]="form">
  <div class="row mt30">
    <div class="col-12 col-md-6 mb20">
      <app-form-field [markCompleted]="true">
        <app-form-field-label>{{
          'general.first_name_l' | translate
        }}</app-form-field-label>
        <input
          appInput
          type="text"
          required
          appAutofocus
          [placeholder]="'forms.type_name' | translate"
          formControlName="firstName"
          class="form-control"
        />
      </app-form-field>
    </div>
    <div class="col-12 col-md-6 mb20">
      <app-form-field [markCompleted]="true">
        <app-form-field-label>{{
          'general.last_name_l' | translate
        }}</app-form-field-label>
        <input
          appInput
          type="text"
          required
          [placeholder]="'forms.type_name' | translate"
          formControlName="lastName"
          class="form-control"
        />
      </app-form-field>
    </div>
    <div class="col-12 mb20">
      <app-form-field [markCompleted]="true">
        <app-form-field-label>{{
          'general.email_address_l' | translate
        }}</app-form-field-label>
        <input
          appInput
          type="text"
          required
          [placeholder]="'forms.type_email_address' | translate"
          formControlName="email"
          class="form-control"
        />
      </app-form-field>
    </div>
  </div>
</form>
