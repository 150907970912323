import { Step } from 'libs/components/legacy/wizard/models';
import * as fromWizardState from '../../+state';
import { editLandlordStepsRoutes } from './edit-landlord.children';
/* eslint-disable  */
export function EditLandlordStepHandler() {
  return function(target: any) {
    target.prototype.nextStep = function(stepName: string) {
      this.store.dispatch(new fromWizardState.NextStep(stepName, this.currentLandlordId));
    };

    target.prototype.selectStep = function(step: Step) {
      const newStepIndex = this.allSteps.findIndex(element => element.name === step.name) + 1;

      if (this.isSelectActionAvailable(step.name)) {
        return this.store.dispatch(new fromWizardState.SetStep(newStepIndex, this.currentLandlordId));
      }
    };

    target.prototype.previousStep = function() {
      const currentStep = this.allSteps[this.currentStepIndex - 1];
      this.store.dispatch(new fromWizardState.PreviousStep(currentStep, this.currentLandlordId));
    };

    target.prototype.getAllSteps = function() {
      return editLandlordStepsRoutes
        .filter(item => !item?.data?.hideOn?.includes(this.propertyFormManager.propertyType.value))
        .map((routeObject: any) => ({
          name: routeObject.path,
          nameNice: routeObject.data.title,
          selectable: this.isStepSelectable(routeObject.path),
        }));
    };

    target.prototype.isSelectActionAvailable = function(newStepName: string) {
      const currentStep = this.allSteps[this.currentStepIndex - 1];

      if (this.isStepBeforeCurrent(newStepName)) return true;

      return newStepName !== currentStep.name && this.currentForm.valid && !this.isAnyStepBeforeInvalid(newStepName);
    };

    target.prototype.isAnyStepBeforeInvalid = function(stepName: string) {
      const foundIndex = this.allSteps.findIndex(step => step.name === stepName);

      return this.allSteps.some((step, index) => index < foundIndex && !this.propertyForm.get(step.name).valid);
    };

    target.prototype.isStepBeforeCurrent = function(stepName: string) {
      const foundIndex = this.allSteps.findIndex(step => step.name === stepName);

      return foundIndex < this.currentStepIndex - 1;
    };

    target.prototype.isStepSelectable = function(stepName: string) {
      return this.allSteps?.length && this.isSelectActionAvailable(stepName);
    };

    target.prototype.getStepIndex = function(name: string) {
      return this.allSteps ? this.allSteps.map(el => el.name).indexOf(name) : -1;
    };

    target.prototype.showStep = function(name: string) {
      return this.getStepIndex(name) > -1;
    };
  };
}
/* eslint-enable  */
