import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { NameValue } from '@ui/shared/models';
import { Store } from '@ngrx/store';
import {
  AppInputDirective,
  CheckboxComponent,
  DropdownMultiselectComponent,
  DropdownSelectComponent,
  FormFieldComponent,
  LoadingSpinnerComponent,
  ModalService
} from 'libs/components/legacy';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { ButtonComponent, InfoCollapseComponent } from 'libs/components/atoms';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import {
  CustomerImportSettings,
  CustomerImportSettingsDelimiterType,
  ObjectHierarchy,
  ObjectHierarchyFieldTypes
} from '../../../models';
import * as fromState from '../../../+state';
import {
  getCustomerImportSettings,
  getCustomerImportSettingsActionState,
  getObjectHierarchy,
  getObjectHierarchyActionState,
  getObjectHierarchyLoadingStatus
} from '../../../+state/landlords/landlords.selectors';
import {
  CreateObjectHierarchy,
  DeleteObjectHierarchy,
  GetObjectHierarchy,
  GetObjectHierarchySettings,
  UpdateObjectHierarchy,
  UpdateObjectHierarchySettings
} from '../../../+state/landlords/landlords.actions';
import { EditLandlordWizard } from '../edit-landlord-wizard';

@UntilDestroy()
@Component({
  selector: 'app-object-hierarchy',
  templateUrl: './object-hierarchy.component.html',
  styleUrls: ['./object-hierarchy.component.scss'],
  imports: [
    InfoCollapseComponent,
    ReactiveFormsModule,
    FormFieldComponent,
    CheckboxComponent,
    DropdownSelectComponent,
    ButtonComponent,
    AppInputDirective,
    DropdownMultiselectComponent,
    LoadingSpinnerComponent,
    TranslateModule,
    AsyncPipe
  ],
  standalone: true
})
export class ObjectHierarchyComponent
  extends EditLandlordWizard
  implements OnInit, OnDestroy
{
  private fb = inject(FormBuilder);
  private store = inject<Store<Store<fromState.EditLandlordState>>>(Store);
  private modalService = inject(ModalService);

  @Input() customerId: string;
  objectHierarchy: ObjectHierarchy[];
  customerImportSettings: CustomerImportSettings;
  loading$: Observable<boolean>;
  public separators: NameValue[] = [
    {
      name: 'admin.customer_import_settings.delimiter.slash_l',
      value: CustomerImportSettingsDelimiterType.SLASH
    },
    {
      name: 'admin.customer_import_settings.delimiter.period_l',
      value: CustomerImportSettingsDelimiterType.PERIOD
    },
    {
      name: 'admin.customer_import_settings.delimiter.dash_l',
      value: CustomerImportSettingsDelimiterType.DASH
    },
    {
      name: 'admin.customer_import_settings.delimiter.underscore_l',
      value: CustomerImportSettingsDelimiterType.UNDERSCORE
    }
  ];
  public fieldTypes: NameValue[] = [
    {
      name: ObjectHierarchyFieldTypes.ADDRESS,
      value: ObjectHierarchyFieldTypes.ADDRESS
    },
    {
      name: ObjectHierarchyFieldTypes.AREA_DESCRIPTION,
      value: ObjectHierarchyFieldTypes.AREA_DESCRIPTION
    },
    {
      name: ObjectHierarchyFieldTypes.ENERGY_CERTIFICATE,
      value: ObjectHierarchyFieldTypes.ENERGY_CERTIFICATE
    }
  ];
  public headerCols: string[] = [
    'admin.object_hierarchy.level_l',
    'admin.object_hierarchy.name_l',
    'admin.object_hierarchy.field_types_l',
    'admin.object_hierarchy.external_id_l',
    'admin.object_hierarchy.actions_l'
  ];
  public form = this.fb.group({
    items: this.fb.array([]),
    customerImportSettings: this.fb.group({
      activated: [false, Validators.required],
      importDelimiter: ['', Validators.required]
    })
  });
  public disableAddLevel: boolean;

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.customerId) {
      this.store.dispatch(new GetObjectHierarchy(+this.customerId));
      this.store.dispatch(new GetObjectHierarchySettings(+this.customerId));

      this.store
        .select(getObjectHierarchyActionState)
        .pipe(
          filter(state => !state.pending && state.done),
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.store
            .select(getObjectHierarchy)
            .pipe(untilDestroyed(this))
            .subscribe(hierarchy => {
              this.objectHierarchy = hierarchy;
              this.initializeHierarchy(hierarchy);
            });
        });

      this.store
        .select(getCustomerImportSettingsActionState)
        .pipe(
          filter(state => !state.pending && state.done),
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.store
            .select(getCustomerImportSettings)
            .pipe(untilDestroyed(this))
            .subscribe(settings => {
              this.customerImportSettings = settings;
              if (this.customerImportSettings) {
                this.customerImportSettingsControl.patchValue(
                  this.customerImportSettings
                );
              }
            });

          if (this.objectHierarchyActive) {
            this.customerImportSettingsControl.get('activated').disable();
          } else {
            this.customerImportSettingsControl.get('activated').enable();
          }
        });

      this.loading$ = this.store.select(getObjectHierarchyLoadingStatus);
    }
  }

  public initializeHierarchy(hierarchy: ObjectHierarchy[]): void {
    this.formItemsControl.clear();
    if (hierarchy.length) {
      hierarchy.forEach(level => {
        if (level) {
          const newGroup = this.fb.group({
            id: [level.id],
            operativeType: [
              {
                value: level.operativeType,
                disabled: this.objectHierarchyActive
              },
              Validators.required
            ],
            hierarchyLevel: level.hierarchyLevel,
            data: this.fb.group({
              name: [level.data?.name, Validators.required],
              fieldTypes: [
                {
                  value: level.data?.fieldTypes,
                  disabled: this.objectHierarchyActive
                }
              ]
            })
          });
          this.formItemsControl.push(newGroup);
          this.disableAddLevel = false;
        }
      });
    } else {
      const newGroup = this.fb.group({
        id: [0, Validators.required],
        operativeType: [false, Validators.required],
        hierarchyLevel: 0,
        data: this.fb.group({
          name: ['', Validators.required],
          fieldTypes: [[]]
        })
      });
      this.formItemsControl.push(newGroup);
      this.disableAddLevel = true;
    }
  }

  public get formItemsControl(): FormArray {
    return this.form.get('items') as FormArray;
  }

  public get customerImportSettingsControl(): FormControl {
    return this.form.get('customerImportSettings') as FormControl;
  }

  public get objectHierarchyActive(): boolean {
    return this.customerImportSettings?.activated;
  }

  public get formLength(): number {
    return +this.form.get('items')['controls'].length;
  }

  public getFieldTypeTranslationKey(
    fieldType: ObjectHierarchyFieldTypes
  ): string {
    return `admin.object_hierarchy.field_type_${String(
      fieldType
    ).toLowerCase()}_l`;
  }

  public addLevel(): void {
    this.disableAddLevel = true;
    const initialGroup = this.fb.group({
      id: [0, Validators.required],
      operativeType: [false, Validators.required],
      data: this.fb.group({
        name: ['', Validators.required],
        fieldTypes: [[]]
      })
    });
    this.formItemsControl.push(initialGroup);
  }

  public saveHierarchy(hierarchy: FormGroup, index: number): void {
    this.modalService
      .openConfirmation({
        data: {
          titleMessage: 'admin.object_hierarchy.are_you_sure_m'
        }
      })
      .onClose()
      .subscribe(() => {
        const payload: ObjectHierarchy = {
          data: hierarchy.value.data,
          operativeType: hierarchy.value.operativeType,
          hierarchyLevel:
            hierarchy.value.id > 0 ? hierarchy.value.hierarchyLevel : index
        };
        this.store.dispatch(
          hierarchy.value.id === 0
            ? new CreateObjectHierarchy(+this.customerId, payload)
            : new UpdateObjectHierarchy(
                +this.customerId,
                hierarchy.value.id,
                payload
              )
        );
        this.disableAddLevel = false;
      });
  }

  public deleteHierarchy(value: ObjectHierarchy, index: number): void {
    if (value.id === 0) {
      this.formItemsControl.removeAt(index);
      this.disableAddLevel = false;
    } else {
      this.modalService
        .openConfirmation({
          data: {
            titleMessage: 'admin.object_hierarchy.are_you_sure_m'
          }
        })
        .onClose()
        .subscribe(() => {
          this.store.dispatch(
            new DeleteObjectHierarchy(+this.customerId, value.id)
          );
        });
    }
  }

  public saveCustomerImportSettings(): void {
    this.modalService
      .openConfirmation({
        data: {
          titleMessage: 'admin.object_hierarchy.are_you_sure_m'
        }
      })
      .onClose()
      .subscribe(() => {
        const settings = this.form.getRawValue()
          .customerImportSettings as CustomerImportSettings;
        this.store.dispatch(
          new UpdateObjectHierarchySettings(+this.customerId, settings)
        );
      });
  }
}
