<form [formGroup]="form">
  <div class="row mt30">
    <div class="col-12 mb20">
      <app-form-field>
        <div class="d-flex flex-row">
          <app-form-field-label>{{
            'general.payment_method_l' | translate
          }}</app-form-field-label>
        </div>
        <app-select
          [required]="true"
          [itemLabelKey]="'name'"
          [itemValueKey]="'value'"
          [items]="paymentMethods"
          [placeholder]="'SELECT.TYPE.L' | translate"
          formControlName="paymentMethod"
        >
        </app-select>
      </app-form-field>
    </div>
  </div>
  @if (showAddress) {
    <div class="col-12 mb20 p0">
      <app-address-form
        [showDistricts]="false"
        [formGroup]="form['controls']?.address"
        [required]="showAddress"
        [config]="addressFormConfig"
      >
      </app-address-form>
    </div>
  }

  <div class="row">
    <div class="col-6 mb15">
      <app-form-field [markCompleted]="true">
        <app-form-field-label>{{
          'general.email_address_l' | translate
        }}</app-form-field-label>
        <input
          appInput
          type="email"
          [placeholder]="'forms.type_email_address_l' | translate"
          formControlName="invoiceEmail"
          class="form-control"
        />
      </app-form-field>
    </div>
  </div>
</form>
