import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { Apollo, QueryRef } from 'apollo-angular';

import { errorMessageParser, getResponseValidator } from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { LandlordUser } from '@ui/shared/models';

import { editLandlordStepsRoutes } from 'admin/screens/landlord/components/edit-landlord/edit-landlord.children';
import {
  notificationConfig as notification,
  notificationConfig
} from 'admin/config';
import * as fromReducers from '../reducers';

import {
  createLandlordMutation,
  createObjectHierarchyMutation,
  CreateObjectHierarchyResponse,
  deleteObjectHierarchyMutation,
  getCustomerImportSettingsQuery,
  GetCustomerImportSettingsResponse,
  getObjectHierarchyQuery,
  GetObjectHierarchyResponse,
  landlordQuery,
  LandlordQueryResult,
  updateCustomerImportSettingsMutation,
  UpdateCustomerImportSettingsResponse,
  updateLandlordMutation,
  UpdateLandlordResponse,
  updateObjectHierarchyMutation,
  UpdateObjectHierarchyResponse
} from '../gql-queries';
import * as fromActions from './landlords.actions';
import * as fromSelectors from './landlords.selectors';

@Injectable()
export class LandlordsEffects {
  private store = inject<Store<fromReducers.EditLandlordState>>(Store);
  private actions$ = inject(Actions);
  private apollo = inject(Apollo);

  private landlordQueryRef: QueryRef<LandlordQueryResult>;
  private objectHierarchyQueryRef: QueryRef<GetObjectHierarchyResponse>;
  private customerImportSettingsQueryRef: QueryRef<GetCustomerImportSettingsResponse>;

  createLandlord$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.CreateLandlord>(fromActions.CREATE_LANDLORD),
      switchMap(({ input }) =>
        this.apollo
          .mutate<{ createLandlord: LandlordUser }>({
            mutation: createLandlordMutation,
            variables: { input },
            update: (cache, { data: { createLandlord } }) => {
              this.refetchLandlord(createLandlord.id);
            }
          })
          .pipe(
            map(
              response =>
                new fromActions.CreateLandlordSuccess(
                  response.data.createLandlord
                )
            ),
            catchError(error => [
              new fromActions.CreateLandlordFail(error),
              new fromBaseState.ShowError(errorMessageParser(error))
            ])
          )
      )
    )
  );

  updateLandlord$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.UpdateLandlord>(fromActions.UPDATE_LANDLORD),
      switchMap(({ input, id }) => {
        return this.apollo
          .mutate<UpdateLandlordResponse>({
            mutation: updateLandlordMutation,
            variables: { input, id: id },
            update: () => this.refetchLandlord(),
            fetchPolicy: 'no-cache'
          })
          .pipe(
            map(
              res =>
                new fromActions.UpdateLandlordSuccess(res.data.updateLandlord)
            ),
            catchError(err => [new fromActions.UpdateLandlordFail(err.message)])
          );
      })
    )
  );

  getLandlord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.GET_LANDLORD),
      switchMap(({ id }: fromActions.GetLandlord) => {
        this.landlordQueryRef = this.apollo.watchQuery<LandlordQueryResult>({
          query: landlordQuery,
          variables: { id },
          fetchPolicy: 'no-cache'
        });

        return this.landlordQueryRef.valueChanges.pipe(
          tap(getResponseValidator<LandlordQueryResult>()),
          map(
            result => new fromActions.GetLandlordSuccess(result.data.landlord)
          ),
          catchError(err => [new fromActions.GetLandlordFail(err.message)])
        );
      })
    )
  );

  landlordActionFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.GET_LANDLORD_FAIL, fromActions.UPDATE_LANDLORD_FAIL),
      map(
        (action: fromActions.GetLandlordFail) =>
          new fromBaseState.ShowError(action.error)
      )
    )
  );

  saveLandlordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UPDATE_LANDLORD_SUCCESS),
      mergeMap(() => [
        new fromBaseState.ShowInfo(notificationConfig.landlord.save.success)
      ])
    )
  );

  changeStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.NEXT_STEP,
        fromActions.PREVIOUS_STEP,
        fromActions.SET_STEP
      ),
      map(({ landlordId }) => landlordId as string),
      withLatestFrom(this.store.select(fromSelectors.getCurrentStepNumber)),
      map(([landlordId, currentStepNumber]) => {
        let path = [
          `/landlord/${landlordId}/edit/${
            editLandlordStepsRoutes[currentStepNumber - 1]?.path
          }`
        ];
        if (!landlordId) {
          path = [
            `landlord/create/${
              editLandlordStepsRoutes[currentStepNumber - 1]?.path
            }`
          ];
        }
        return new fromBaseState.Go({
          path
        });
      })
    )
  );

  getObjectHierarchy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.GET_OBJECT_HIERARCHY),
      switchMap(({ customerId }: fromActions.GetObjectHierarchy) => {
        this.objectHierarchyQueryRef =
          this.apollo.watchQuery<GetObjectHierarchyResponse>({
            query: getObjectHierarchyQuery,
            variables: { customerId },
            fetchPolicy: 'no-cache'
          });

        return this.objectHierarchyQueryRef.valueChanges.pipe(
          tap(getResponseValidator<GetObjectHierarchyResponse>()),
          map(
            result =>
              new fromActions.GetObjectHierarchySuccess(
                result.data.getObjectHierarchy.sort(
                  (a, b) => a.hierarchyLevel - b.hierarchyLevel
                )
              )
          ),
          catchError(error => [
            new fromBaseState.ShowError(errorMessageParser(error)),
            new fromActions.GetObjectHierarchyFail(error)
          ])
        );
      })
    )
  );

  createObjectHierarchy$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.CreateObjectHierarchy>(
        fromActions.CREATE_OBJECT_HIERARCHY
      ),
      switchMap(
        ({ customerId, objectHierarchy }: fromActions.CreateObjectHierarchy) =>
          this.apollo
            .mutate<CreateObjectHierarchyResponse>({
              mutation: createObjectHierarchyMutation,
              variables: { customerId, objectHierarchy },
              update: (cache, { data: { createObjectHierarchy } }) => {
                this.refetchObjectHierarchy(createObjectHierarchy.id);
              }
            })
            .pipe(
              mergeMap(result => {
                return [
                  new fromActions.CreateObjectHierarchySuccess(
                    result.data.createObjectHierarchy
                  ),
                  new fromBaseState.ShowInfo(
                    notification.objectHierarchy.action.success
                  )
                ];
              }),
              catchError(error => [
                new fromBaseState.ShowError(errorMessageParser(error)),
                new fromActions.CreateObjectHierarchyFail(error)
              ])
            )
      )
    )
  );

  updateObjectHierarchy$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.UpdateObjectHierarchy>(
        fromActions.UPDATE_OBJECT_HIERARCHY
      ),
      switchMap(
        ({
          customerId,
          objectHierarchy,
          administrationUnitType
        }: fromActions.UpdateObjectHierarchy) =>
          this.apollo
            .mutate<UpdateObjectHierarchyResponse>({
              mutation: updateObjectHierarchyMutation,
              update: (cache, { data: { updateObjectHierarchy } }) => {
                this.refetchObjectHierarchy(updateObjectHierarchy.id);
              },
              variables: {
                customerId,
                administrationUnitType,
                objectHierarchy
              }
            })
            .pipe(
              mergeMap(result => {
                return [
                  new fromActions.UpdateObjectHierarchySuccess(
                    result.data.updateObjectHierarchy
                  ),
                  new fromBaseState.ShowInfo(
                    notification.objectHierarchy.action.success
                  )
                ];
              }),
              catchError(error => [
                new fromActions.UpdateObjectHierarchyFail(error),
                new fromBaseState.ShowError(errorMessageParser(error))
              ])
            )
      )
    )
  );

  deleteObjectHierarchy$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.DeleteObjectHierarchy>(
        fromActions.DELETE_OBJECT_HIERARCHY
      ),
      switchMap(
        ({
          customerId,
          administrationUnitType
        }: fromActions.DeleteObjectHierarchy) =>
          this.apollo
            .mutate<CreateObjectHierarchyResponse>({
              mutation: deleteObjectHierarchyMutation,
              update: () => {
                this.refetchObjectHierarchy(customerId);
              },
              variables: {
                customerId,
                administrationUnitType
              }
            })
            .pipe(
              mergeMap(() => {
                return [
                  new fromActions.DeleteObjectHierarchySuccess(),
                  new fromBaseState.ShowInfo(
                    notification.objectHierarchy.action.success
                  )
                ];
              }),
              catchError(error => [
                new fromActions.DeleteObjectHierarchyFail(error),
                new fromBaseState.ShowError(errorMessageParser(error))
              ])
            )
      )
    )
  );

  getHierarchySettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.GET_CUSTOMER_IMPORT_SETTINGS),
      switchMap(({ customer }: fromActions.GetObjectHierarchySettings) => {
        this.customerImportSettingsQueryRef =
          this.apollo.watchQuery<GetCustomerImportSettingsResponse>({
            query: getCustomerImportSettingsQuery,
            variables: { customer },
            fetchPolicy: 'no-cache'
          });

        return this.customerImportSettingsQueryRef.valueChanges.pipe(
          tap(getResponseValidator<GetCustomerImportSettingsResponse>()),
          mergeMap(result => {
            return [
              new fromActions.GetObjectHierarchySettingsSuccess(
                result.data.getCustomerImportSettings
              )
            ];
          }),
          catchError(error => [
            new fromActions.GetObjectHierarchySettingsFail(error),
            new fromBaseState.ShowError(errorMessageParser(error))
          ])
        );
      })
    )
  );

  updateCustomerImportSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.UpdateObjectHierarchySettings>(
        fromActions.UPDATE_CUSTOMER_IMPORT_SETTINGS
      ),
      switchMap(
        ({
          customer,
          importSettings
        }: fromActions.UpdateObjectHierarchySettings) =>
          this.apollo
            .mutate<UpdateCustomerImportSettingsResponse>({
              mutation: updateCustomerImportSettingsMutation,
              update: () => {
                this.refetchObjectHierarchySettings(customer);
              },
              variables: { customer, importSettings }
            })
            .pipe(
              mergeMap(() => {
                return [
                  new fromActions.UpdateObjectHierarchySettingsSuccess(),
                  new fromBaseState.ShowInfo(
                    notification.objectHierarchy.action.success
                  )
                ];
              }),
              catchError(error => [
                new fromActions.UpdateObjectHierarchySettingsFail(error),
                new fromBaseState.ShowError(errorMessageParser(error))
              ])
            )
      )
    )
  );

  private refetchLandlord(userId?: string) {
    if (!this.landlordQueryRef && userId) {
      this.store.dispatch(new fromActions.GetLandlord(userId));
    }
    if (!this.landlordQueryRef) return;
    void this.landlordQueryRef.refetch();
  }

  private refetchObjectHierarchy(customerId?: number) {
    if (!this.objectHierarchyQueryRef && customerId) {
      this.store.dispatch(new fromActions.GetObjectHierarchy(customerId));
    }
    if (!this.objectHierarchyQueryRef) return;
    void this.objectHierarchyQueryRef.refetch();
  }

  private refetchObjectHierarchySettings(customerId?: number) {
    if (!this.customerImportSettingsQueryRef && customerId) {
      this.store.dispatch(
        new fromActions.GetObjectHierarchySettings(customerId)
      );
    }
    if (!this.customerImportSettingsQueryRef) return;
    void this.customerImportSettingsQueryRef.refetch();
  }
}
